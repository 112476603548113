<template>
  <div class="order-wrapper">
    <div class="order-header">
      <div class="row d-flex align-items-center">
        <div class="col-6">
          Order Number:
          <a :href="order.links.self">{{ order.id_formatted }}</a>
          <br>
            <span>
              {{ createdAt }}
            </span>
          <!-- </div> -->
        </div>
        <div class="col-6 text-right">
          <countdown :time="timeLeft" :interval="100" v-if="!orderExpired">
            <template slot-scope="props">

              <span class="badge badge-light px-3 py-2 text-center">
                <strong class="h6 mb-1 d-block">{{ props.days }} DAYS</strong>
                <div class="">
                  {{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}
                </div>
              </span>
            </template>
          </countdown>
          <div v-else >
            <span class="badge badge-danger px-3 py-2">Expired</span>
          </div>
        </div>
      </div>
    </div>

    <div class="order-body">
      <div class="row">
        <div class="col-3">
          <div class="mb-3">
            <strong>User Delivery Address</strong>
            <div class="sub text-black-50">
              {{ order.shipping_formatted }}
            </div>
          </div>
          <div class="mb-3">
            <strong>User Full Name</strong>
            <div class="sub text-black-50">
              {{ order.shipping.first_name }}
              {{ order.shipping.last_name }}
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="mb-3">
            <strong>User Phone Number</strong>
            <div class="sub text-black-50">
              {{ order.shipping.phone }}
            </div>
          </div>
          <div class="mb-3">
            <strong>User Email</strong>
            <div class="sub text-black-50">
              {{ order.shipping.email }}
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="mb-3">
            <strong>Ship By Date</strong>
            <div class="sub text-black-50">
              {{ order.ship_by_date }}
            </div>
          </div>
          <div class="mb-3">
            <strong>Return By Date</strong>
            <div class="sub text-black-50">
              {{ order.return_at }}
            </div>
          </div>
        </div>
        <div class="col-3 text-center">
          <div class="mb-3">
            <strong>Status</strong>
            <div class="sub text-black-50 text-uppercase">{{ sub_order.status }}</div>
          </div>
        </div>
      </div>

      <hr v-if="showLabels">
      <div class="row" v-if="showLabels">
        <div class="col-3">
          <div class="mb-3">
            <a class='btn btn-primary' v-if='sub_order.vendor_shipment.shipment_id' :href='sub_order.vendor_shipment.label_url' target='_blank'>Outgoing Shipment Label</a>
            <button class='btn btn-outline-primary' v-else-if="!orderReturned" @click='sendSubOrder'>{{shipment_generate_button}}</button>
          </div>
        </div>
        <div class="col-3">
          <div class="mb-3">
            <a class='btn btn-primary' v-if='sub_order.user_shipment.shipment_id' :href='sub_order.user_shipment.label_url' target='_blank'>Return Shipment Label</a>
            <button class='btn btn-outline-primary' v-else-if="!orderReturned" @click='sendSubOrder'>{{return_generate_button}}</button>
          </div>
        </div>
        <div class="col-3">
          <div class="mb-3">
            <strong>Outgoing Shipment Tracking ID:</strong>
            <a class="track-link"
              v-if='sub_order.vendor_shipment.tracking_code'
              :href='sub_order.vendor_shipment.tracking_url'
              target='_blank'>{{ sub_order.vendor_shipment.tracking_code }}
            </a>
          </div>
        </div>
        <div class="col-3">
          <div class="mb-3">
            <strong>Return Shipment Tracking ID:</strong>
            <a class="track-link"
                v-if='sub_order.user_shipment.tracking_code'
                :href='sub_order.user_shipment.tracking_url'
                target='_blank'>{{ sub_order.user_shipment.tracking_code }}
              </a>
          </div>
        </div>
      </div>

      <div v-if='isForm' class="border rounded row fedex-section">
        <div v-if='isForm' class="col" id="outgoing-service-options">
          <div class="form-item">
            <p class="price-warning" v-if="changedDefaultOutgoingService">This increases the shipment price</p>
            <div  class="form-select">
              <label for="outgoing-service-options">Choose Outgoing FedEx Service Option</label>
              <select v-model="shipping_options.outgoing_service" class="mb-3 custom-select">
                <option v-for="service_option in service_options" :key="service_option[1]" :value="service_option[1]">
                  {{ service_option[0] }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-item" style="padding-top: 11px">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="outgoing-hold-at" v-model="shipping_options.outgoing_hold_at">
              <label class="form-check-label" for="outgoing-hold-at">Outgoing shipment to nearest FedEx point</label>
            </div>
          </div>

        </div>

        <div v-if='isForm' class="col" id="return-service-options">
          <div class="form-item">
            <p class="price-warning" v-if="changedDefaultReturnService">This increases the shipment price</p>
            <div  class="form-select">
              <label for="return-service-options">Choose Return FedEx Service Option</label>
              <select v-model="shipping_options.return_service" class="mb-3 custom-select">
                <option v-for="service_option in service_options" :key="service_option[1]" :value="service_option[1]">
                  {{ service_option[0] }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-item" style="padding-top: 11px">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="return-hold-at" v-model="shipping_options.return_hold_at">
              <label class="form-check-label" for="return-hold-at">Return shipment to nearest FedEx point</label>
            </div>
            <p class="price-warning" v-if="changedDefaultReturnHoldAt">This increases the shipment price</p>
          </div>

        </div>

        <div v-if='isForm' class="col align-self-center">
          <button @click='sendSubOrder' :disabled="isDisabled" class="btn btn-primary send-order">Send Order</button>
        </div>
      </div>

      <order-item v-for='order_item in sub_order.order_items.data'
               :key='order_item.id'
               :order_item='order_item'
               :sub_order='sub_order'
               :order_expired='orderExpired'
               @update-order-item='updateOrderItem'
               @update-sub-order='updateSubOrder'
               @review-modal='reviewModal'
               @show-review-modal='showReviewModal'>
      </order-item>

    </div>
  </div>
</template>

<script>
import JsonApi from "../../../../vendor/json-api";
import * as Toastr from 'toastr';
import OrderItem from './order_item'
import VueCountdown from '@chenfengyuan/vue-countdown';
import _ from "lodash"
import * as moment from 'moment';

export default {
  components: {
    OrderItem: OrderItem,
    Countdown: VueCountdown
  },
  props: {
    sub_order: { type: Object, required: true }
  },
  data() {
    return {
      shipment_generate_button: 'Generate Outgoing Shipment Label',
      return_generate_button: 'Generate Return Shipment Label',
      order: this.sub_order.order.data,
      service_options: [
        ['FedEx Standard Overnight', 'STANDARD_OVERNIGHT'],
        ['Fedex Express Saver', 'FEDEX_EXPRESS_SAVER'],
        ['Fedex 2 Day', 'FEDEX_2_DAY']
      ],
      shipping_options: {
        return_service: 'FEDEX_EXPRESS_SAVER',
        outgoing_service: 'FEDEX_EXPRESS_SAVER',
        outgoing_hold_at: false,
        return_hold_at: true
      },

    }
  },
  computed: {
    timeLeft() {
      const now = new Date();
      const shipByDate = new Date(this.order.ship_by_date);
      return shipByDate - now;
    },
    createdAt() {
      return moment(this.order.created_at).format('Do MMM YYYY H:m:s')
    },
    orderExpired() {
      return this.timeLeft <= 0;
    },
    orderCancelled() {
      return this.sub_order.status === 'cancelled';
    },
    orderReturned() {
      return this.sub_order.status === 'returned';
    },
    orderLabelsReady() {
      if (this.sub_order.user_shipment.shipment_id || this.sub_order.vendor_shipment.shipment_id) {
        return true;
      }
    },
    isDisabled() {
      return !this.sub_order["user_verified?"];
    },
    showLabels() {
      if (this.orderExpired || this.orderCancelled) {
        return false;
      }

      return !!this.sub_order.user_shipment.shipment_id || !!this.sub_order.vendor_shipment.shipment_id
    },
    changedDefaultOutgoingService() {
      return this.shipping_options.outgoing_service !== 'FEDEX_EXPRESS_SAVER'
    },
    changedDefaultReturnService() {
      return this.shipping_options.return_service !== 'FEDEX_EXPRESS_SAVER'
    },
    changedDefaultReturnHoldAt() {
      return this.shipping_options.return_hold_at === false
    },
    confirmSendMessage() {
      if (this.changedDefaultReturnService || this.changedDefaultOutgoingService || this.changedDefaultReturnHoldAt) {
        return 'Chosen delivery options will increase the price. Are you sure?'
      }
      return 'Are you sure?'
    },
    isForm() {
      return !this.orderLabelsReady && !this.orderExpired && !this.orderReturned && !this.orderCancelled
    }
  },
  methods: {
    showReviewModal(review) { this.$emit('show-review-modal', review) },
    reviewModal(path, id) { this.$emit('review-modal', path, id) },
    updateOrderItem(event) { this.$emit('update-order-item', event) },
    updateSubOrder(event) { this.$emit('update-sub-order', event) },
    sendSubOrder() {
      let that = this
      if(confirm(this.confirmSendMessage)) {
        $(that.$el).find('button.send-order').text('Generating...')
        $.ajax({
          url: '/vendors/sub_orders/shipments',
          method: 'POST',
          data: {
            id: that.sub_order.id,
            shipping_options: this.shipping_options
          },
          success(response) {
            that.$emit('update-sub-order', JsonApi.deserialize(response.sub_order))

            if(!_.isEmpty(response.errors)) {
              Toastr.error(response.errors)
              $(that.$el).find('button.send-order').text('Send Order')
            } else {
              Toastr.success('Order labels have been generated')
            }
          },
          error() {
            Toastr.error('There was an error with label generation')
            $(that.$el).find('button.send-order').text('Send Order')
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">
  a.track-link{
    text-decoration: underline;
  }

  .aligned-item{
    display: flex;
    justify-content: right;
    column-gap: 15px;
  }

  .send-order[disabled] {
    cursor: not-allowed;
  }

  .form-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .price-warning {
    color: red;
    font-weight: bold;
  }

  .fedex-section {
    padding: 15px 0;
    margin: 15px 0;
    border-width: 3px !important;;
    border-color: rebeccapurple !important;
  //  important cause i dont want to mess with the whole bootstrap theme for one border, and because they are using it themselves so need to overwrite their importants...
  }
</style>
